<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('thirdParty_reportExport.kpiExport') }}
    </div>
    <simple-form
      ref="form"
      v-model="formModel"
      label-width="80px"
      :form-field="formField"
      :view-flag="false"
    />

    <!-- Footer -->
    <div slot="footer">
      <el-button @click="currentShowFlag=false">
        {{ $t('operation.cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="handleSubmitClick"
      >
        {{ $t('operation.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
const BASEURL = {
  office: '/system/office/list',
  export: '/jlr/tt/selfExaminationData/kpiExport'
}
export default {
  props: {
    showFlag: { type: Boolean, default: false }
  },
  data () {
    return {
      formModel: {},
      dealerLoading: false
    }
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          label: this.$t('thirdParty_reportExport.month'),
          component: { clearable: true, type: 'month', valueFormat: 'yyyyMM' },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'bizStatus',
          type: 'Select',
          label: this.$t('thirdParty_reportExport.status'),
          component: {
            optionList: this.$getDictList('tt_self_examination_data_head_biz_status'),
            valueKey: 'id',
            clearable: true
          },
          event: {
            change: val => {
              if (val !== 'V4') {
                this.$set(this.formModel, 'version', '')
              }
            }
          },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'dealer',
          label: this.$t('dealer_finance.dealer'),
          type: 'SearchableInput',
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        {
          prop: 'version',
          type: 'Input',
          label: this.$t('thirdParty_reportExport.version'),
          component: { clearable: true, disabled: this.formModel.bizStatus !== 'V4' },
          rules: this.formModel.bizStatus === 'V4' ? { trigger: 'blur', message: this.$t('validate.notBlank'), required: true } : {}
        },
        {
          prop: 'isSubmit',
          type: 'Select',
          label: this.$t('dealer_finance.isSubmit'),
          component: {
            optionList: this.$getDictList('yes_no'),
            valueKey: 'id',
            clearable: true
          },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleDialogOpen () {},
    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = { filter: [{ left: 'category', operate: '=', right: 'sys_office_category_3' }] }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.dealerList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    handleSubmitClick () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitModel = this.$_.cloneDeep(this.formModel)
          if (submitModel.dealer) {
            submitModel.dealerId = submitModel.dealer.id
            submitModel.dealerName = submitModel.dealer.name
          }
          this.$axios
            .post(
              BASEURL.export,
              submitModel
            )
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    }
  }
}
</script>

<style>

</style>
